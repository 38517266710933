<template>
  <div class="chips chips-operator">
    <div class="chips__label">
      {{ $locale.main.calculator.operators[model.name] }}
    </div>
    <!--<a class="chips__remove"></a>-->
  </div>
</template>

<script>
export default {
  name: 'ChipsOperator',

  props: {
    value: Object
  },

  data () {
    return {
      model: this.value
    }
  },

  mounted () {
    console.log('ChipsOperator', this.value)
  }
}
</script>

<style scoped></style>
