<template>
  <div class="chips chips-operand">
    <div class="chips__label">
      {{ $locale.main.calculator.operands[model.name] }}
    </div>
    <span class="chips__open"></span>
    <div class="chips__arguments">
      <chips-index v-model="model"></chips-index>
    </div>
    <span class="chips__close"></span>
    <!--<a class="chips__remove"></a>-->
  </div>
</template>

<script>
export default {
  name: 'ChipsOperand',

  props: {
    value: Object
  },

  data () {
    return {
      model: this.value
    }
  },

  mounted () {
    console.log('ChipsOperand', this.model)
  }
}
</script>

<style scoped></style>
