<template>
  <div class="chips chips-expr">
    <div class="chips__label">
      ВЫРАЖЕНИЕ
    </div>
    <span class="chips__open"></span>
    <div class="chips__arguments">
      <div class="chips__arguments-item">
        <chips-field v-model="model.field"></chips-field>
      </div>
      <div class="chips__arguments-item">
        <chips-operator v-model="model.operator"></chips-operator>
      </div>
      <div class="chips__arguments-item">
        <chips-value v-model="model.value"></chips-value>
      </div>
    </div>
    <span class="chips__close"></span>
    <!--<a class="chips__remove"></a>-->
  </div>
</template>

<script>
import ChipsField from './ChipsField'
import ChipsOperator from './ChipsOperator'
import ChipsValue from './ChipsValue'

export default {
  name: 'ChipsExpr',

  props: {
    value: Object
  },

  components: {
    ChipsField,
    ChipsOperator,
    ChipsValue
  },

  data () {
    return {
      model: this.value
    }
  },

  mounted () {
    console.log('ChipsExpr', this.value)
  }
}
</script>

<style scoped></style>
