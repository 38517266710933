<template>
  <div class="chips chips-index">
    <div class="chips__arguments">
      <div class="chips__arguments-item" v-for="(item, index) in model.children" :key="`chips_item_${index}`">
        <component
          v-model="model.children[index]"
          :is="chips[item.type]">
        </component>
      </div>
    </div>
    <!--<div class="chips__tools">
      <el-button type="success" circle size="mini" icon="el-icon-plus"></el-button>
      <el-button type="danger" circle size="mini" icon="el-icon-delete"></el-button>
    </div>-->
  </div>
</template>

<script>
import ChipsExpr from './ChipsExpr'
import ChipsField from './ChipsField'
import ChipsFunction from './ChipsFunction'
import ChipsLogicOperator from './ChipsLogicOperator'
import ChipsOperand from './ChipsOperand'
import ChipsValue from './ChipsValue'

export default {
  name: 'ChipsIndex',

  props: {
    value: Object
  },

  components: {
    ChipsExpr,
    ChipsField,
    ChipsFunction,
    ChipsLogicOperator,
    ChipsOperand,
    ChipsValue
  },

  data () {
    return {
      chips: {
        expr: 'ChipsExpr',
        field: 'ChipsField',
        function: 'ChipsFunction',
        logic_operator: 'ChipsLogicOperator',
        operand: 'ChipsOperand',
        value: 'ChipsValue'
      },

      model: this.value
    }
  },

  mounted () {
    console.log('ChipsIndex', this.model)
  }
}
</script>

<style scoped></style>
