<template>
  <div class="chips chips-field">
    <div class="chips__label">
      {{ model.label }}
    </div>
    <!--<a class="chips__remove"></a>-->
  </div>
</template>

<script>
export default {
  name: 'ChipsField',

  props: {
    value: Object
  },

  data () {
    return {
      model: this.value
    }
  },

  mounted () {
    console.log('ChipsField', this.value)
  }
}
</script>

<style scoped></style>
